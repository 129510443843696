@font-face {
  font-family: Monserate;
  src: url('../public/MONTSERRAT-MEDIUM.OTF');
}

html {
  font-family: Monserate, sans-serif;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: Monserate, sans-serif;
  }
}

* {
  box-sizing: border-box;
}

html,
body,
#root,
#main {
  font-family: Monserate, sans-serif;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  user-select: none;
  background: black;
  /* background: #959595;
  background: repeating-linear-gradient(to top, #959595 0%, #0D0D0D 24%, #010101 58%, #0A0A0A 70%, #4E4E4E 100%, #383838 100%, #1B1B1B 100%); */

}

body {
  background: #141414;
  font-family: Monserate, sans-serif!important;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.on{
  display: flex;
  align-content: center;
  align-self: center;
  justify-content: center;
  padding: 2px;
  margin: auto;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.on:hover {
  cursor: pointer;
  opacity: 1;
  background-color: rgba(255, 132, 0, 0.335);
}

.content {
  padding-top: 10px;
  transform: translate3d(calc(50% + 40px), 0, 0);
  text-align: left;
  background: #202035;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
}

.content::before {
  content: "";
  position: absolute;
  top: 25px;
  left: -40px;
  height: 1px;
  width: 40px;
  background: black;
}

.custom-loader {
  height: 4px;
  width: 130px;
  background:
    linear-gradient(#6EB433 0 0),
    linear-gradient(#6EB433 0 0),
    #E4E4ED;
  background-size: 60% 100%;
  background-repeat: no-repeat;
  animation: p6 3s infinite;
  border-radius: 25px;
}

@keyframes p6 {
  0% {
    background-position: -150% 0, -150% 0
  }

  66% {
    background-position: 250% 0, -150% 0
  }

  100% {
    background-position: 250% 0, 250% 0
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 1s ease 0.3s forwards;
}

.annotation {
  transition: all 0.5s ease-in-out;
  box-shadow: inset 0 0 0 0.09px white;
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 8px;
  font-weight: 300;
  background: indianred;
  color: white;
  padding: 2px 10px;
  border-radius: 20px;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.annotation:hover {
  box-shadow: inset 250px 0 0 0 white;
  color: black;
}

header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 40px;
  align-items: center;
  position: fixed;
}

header p {
  font-weight: bold;
  font-size: 0.8rem;
  transition: all 1.2s ease-out;
}

header p.hidden {
  opacity: 0;
}

section {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.section--container {
  margin-top: 5vh;
  margin-left: 5vw;
}

.section--container.hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.section--container h1 {
  font-weight: 900;
  font-size: 13rem;
  letter-spacing: -6px;
  font-style: italic;
  width: 30%;
  line-height: 10rem;
  font-family: 'Nunito Sans', sans-serif;
}

.support--content {
  position: relative;
  top: -25%;
  left: 300px;
}

.support--content p {
  width: 350px;
  margin-bottom: 48px;
  line-height: 1.5rem;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
}

button {
  /* background-color: #dd5c18; */
  border: none;
  border-radius: 1px;
  color: white;
  font-weight: bolder;
  pointer-events: all;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  /* box-shadow: inset 0 0 0 0.09px black; */
  background-color: rgba(84, 84, 84, 0.367);
  text-transform: capitalize;
  display: flex;
  align-items: center;
  padding: 15px 30px;
}

button:hover {
  box-shadow: inset 280px 0 0 0 #e8e8e8;
  /* transform: scale(1.1); */
}

/* Side Menu */
.side {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  top: 30%;
  right: 5%;
}

.popup {
  opacity: 0;
  display: flex;
  border: #dd5c18;
  animation: fade-in 1s ease 1s forwards;
  position: absolute !important;
  top: 25% !important;
  width: 100%;
  left: 5% !important;
  margin: auto;
}


.popup-bg {
  /* From https://css.glass */
  background: rgba(42, 41, 41, 0.446);
  border-radius: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.closeBtn{
  right: 15px;
  top:10px;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  position: absolute;
  cursor: pointer;
  z-index: 999;
  background: transparent;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  color: #e8e8e8;
  font-size: 25px;
}
.closeBtn:hover{
  color: black;
  background: rgba(255, 255, 255, 0.2);
}
.menu {
  top: 30%;
  right: 5%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  height: max-content;
  width: 200px;
  background-color: rgba(37, 40, 41, 0.367);
  padding: 10px;
  margin: 2px;
  border: white 2px solid;
  border-radius: 15px;
  box-shadow: #202035;
}

.menu-list {
  cursor: pointer;
  color: #ffffff;
  font-weight: 500;
  font-size: medium;
  text-transform: capitalize;
}

.menu-list:hover {
  cursor: pointer;
  text-decoration: underline;
}
.wrap-menu-mobile{
  display: none;
}
.wrap-menu{
  position: absolute;
  display: flex;
  gap: 5px;
  width: 300px;
  padding: 10px;
  height: fit-content;
  top: 15%;
  right: 5%;
  flex-direction: column;
  align-content: space-between;
}
.ui-home {
  display: flex;
  align-self: end;
}
.ui-home button {
  height: 25px;
  border: none;
  background-color: rgba(42, 41, 41, 0.446);
}

.ui-home button:hover {
  color: #333334;
}
.ui-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* transform: translateY(-50%); */
  /* padding: 5px 5px; */
  border-radius: 10px;
  color: #333334;
  border: none;
  
}
.ui-material{
  /* gap: 5px; */
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 50px;
  /* transform: translateY(-50%); */
  color: #333334;
  border: none;
  background-color: rgba(42, 41, 41, 0.446);
  /* background-color: #e8e8e8; */
}
.ui-credit{
  display: flex;
  padding: 5px 10px;
  flex-direction: column;
  width: 100%;
  /* transform: translateY(-50%); */
  color: white;
  border: none;
  background-color: rgba(42, 41, 41, 0.446);
  /* background-color: #e8e8e8; */
}
.credit-item a{
  color: rgb(157, 157, 157);
}
.credit-item{
  color: rgb(150, 150, 150);
  font-size: 10px;
  font-style: italic;
}
.emoti{
  font-style: normal;
}
.credit-btn {
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 2px;
  padding-bottom: 3px;
  font-size: smaller;
}
.credit-btn:hover{
  color: grey;
}
.wrap-fullscreen{
  position: absolute;
  display: flex;
  padding: 10px;
  bottom: 2%;
  left: 2%;
  flex-direction: row;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
}
.wrap-fullscreen span{
  opacity: 0;
  padding-left: 6px;
  transition: all 0.5s ease;
  color: #ddd8d5;
}
.ui-fullscreen-btn{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 20px;
  color: white;
  border: white 1px solid;
  background-color: transparent;
  border-radius: 2px;
  border: white 0.5px solid;
  transition: all 0.2s ease;
}
.ui-fullscreen-btn:hover{
  background-color: #919191;
  color: rgb(0, 0, 0);
  border: white 1px solid;
  scale: 1.2;
}
.ui-fullscreen-btn:hover ~ span{
  opacity: 1;
}
.ui-menu button {
  font-family: Monserate, sans-serif;
  width: 100%;
  display: block;
  margin: 2px 0;
  background: none;
  border: none;
  color: #e8e8e8;
  font-size: 12px;
  cursor: pointer;
  text-align: left;
  /* background-color: rgba(131, 133, 134, 0.367); */
  background-color: rgba(42, 41, 41, 0.446);
}

.ui-menu button:hover {
  color: #000000;
  font-weight:bolder;
}
.ui-menu button.active {
  color: #333334;
  font-weight:bolder;
  /* background-color: #333334; */
  background-color: #e8e8e8;
}
.ui-menu button.active:hover {
  color: #000000;
  font-weight:bolder;
}
.logo{
  display: flex;
  width: 100%;
  justify-content: center;
}

/* CUSTOMIZER */

.customizer {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-bottom: 25px;
}

.customizer span {
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
}

.customizer span svg {
  width: 24px;
  padding-right: 5px;
}

.share {
  bottom: 40px;
  position: absolute;
  right: 40px;
}

.zoom {
  position: absolute;
  right: 20px;
  top: 50%;
  color: #00000056;
  font-size: 0.6rem;
  border: 1px solid #00000000;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: border, color 1s ease;
}

.zoom:hover {
  color: black;
}

/* PICKER */

.picker {
  position: absolute !important;
  bottom: 4em;
  left: 50%;
  transform: translateX(-50%);
  width: 120px !important;
  height: 120px !important;
}

.material-options {
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.square {
  display: block;
  height: 30px;
  width: 30px;
  border-radius: 5%;
  border: 0.5px solid #ffffff;
  transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
  box-sizing: border-box;
}

.square:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.color-options {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid white;
  transition: transform 0.6s cubic-bezier(0.85, 0, 0.15, 1);
}

.circle:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.decals {
  position: absolute;
  left: 50px;
  bottom: 40px;
}

.decals p {
  font-family: 'Inter var';
  font-size: 0.7rem;
  padding-bottom: 15px;
}

.decals img {
  width: 24px;
  filter: saturate(0) invert(1) brightness(5.5);
  transition: all 0.2s ease-in-out;
}

.decals img:hover {
  filter: none;
  transform: scale(1.2);
  cursor: pointer;
}

.decals--position {
  position: absolute;
  left: 20px;
  top: 50%;
  color: #00000056;
  font-size: 0.6rem;
  border: 1px solid #00000000;
  padding: 10px 10px;
  border-radius: 50px;
  font-weight: 600;
  cursor: pointer;
  transition: border 1s ease;
}

.decals--position:hover {
  color: black;
  border: 1px solid #00000056;
}

.upload {
  margin-top: -5px;
  cursor: pointer;
}

.upload:hover {
  color: white;
}

.decals--container {
  display: flex;
  gap: 20px;
}

.exit {
  position: absolute;
  top: 40px;
  right: 40px;
  background-color: black;
  box-shadow: inset 0 0 0 0.09px black;
}

.exit:hover {
  box-shadow: inset -250px 0 0 0 black;
}
.logoMobile{
  display: none;
}
@media only screen and (max-width: 600px)  {
  /* For phone: */
  .wrap-fullscreen {
    display: none;
  }
  .logo{
    display: none;
  }
  .logoMobile{
    display: flex;
    align-content: start;
    justify-content: start;
    position: absolute;
    top: 5px;
    width: 40%;
    left: 0px;
  }
  .popup {
    justify-content: center;
    align-content: center;
    display: flex;
    border: #dd5c18;
    animation: fade-in 1s ease 1s forwards;
    position: absolute !important;
    top: 10% !important;
    width: 100%;
    left: 0% !important;
    margin: auto;
  }
  .wrap-menu-mobile{
    display: block;
    position: absolute;
    /* gap: 5px; */
    width: 300px;
    /* padding: 10px; */
    height: fit-content;
    top: auto;
    bottom: 1.5%;
    right: 5%;
    align-items: end;
  }
  .ui-row{
    display: flex;
    padding: 10px 0px;
    gap: 5px;
    justify-content: end;
  }
  .ui-row>.ui-home{
    margin: 2px;
  }
  .wrap-menu{
    display: none;
  }
  .ui-menu {
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    width: 70%;
    border-radius: 10px;
    color: #333334;
    border: none;
    
  }
  .credit-item{
    font-size: 10px;
  }
}

@media only screen and (max-width: 768px) {
  /* For tablet: */
  /* .popup {
    top: 10% !important;
    width: 40%;
    left: 10% !important;
  } */
  .wrap-fullscreen {
    display: none;
  }
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .wrap-fullscreen {
    display: none;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px)  and (orientation: landscape)  {
  .logo{
    display: none;
  }
  .logoMobile{
    display: block;
  }
  .wrap-fullscreen {
    display: none;
  }
}
